import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import theme from 'src/theme'
import DefaultTemplate from 'templates/default-template'
import 'styles/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export function wrapPageElement({ element }) {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: { position: 'top', duration: 5000, isClosable: true },
      }}
    >
      <DefaultTemplate>
        <AnimatePresence mode="wait">{element}</AnimatePresence>
      </DefaultTemplate>
    </ChakraProvider>
  )
}
