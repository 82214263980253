const colors = {
  black: "#000000",
  gray: {
    50: "#f9fafa",
    100: "#f1f1f2",
    200: "#e6e7e9",
    300: "#d2d4d7",
    400: "#a9adb2",
    500: "#797f88",
    600: "#4d5560",
    700: "#2e3744",
    800: "#19202b",
    900: "#141a23",
  },
  yellow: {
    50: "#fffef9",
    100: "#fefad9",
    200: "#fdef88",
    300: "#f7e133",
    400: "#e2cd2f",
    500: "#baa927",
    600: "#95871f",
    700: "#746918",
    800: "#574f12",
    900: "#48410f",
  },
  green: {
    50: "#f0fff8",
    100: "#a2fdd4",
    200: "#32f29b",
    300: "#2dd88b",
    400: "#27bd79",
    500: "#22a268",
    600: "#1c8756",
    700: "#166943",
    800: "#125637",
    900: "#0f472d",
  },
  teal: {
    50: "#eafeff",
    100: "#97f8fd",
    200: "#33eaf4",
    300: "#2dd1da",
    400: "#26b2b9",
    500: "#21979d",
    600: "#1a7b80",
    700: "#155f63",
    800: "#115053",
    900: "#0e4144",
  },
  cyan: {
    50: "#f1fcff",
    100: "#c2f1fe",
    200: "#a5eafd",
    300: "#81e2fd",
    400: "#31c3ec",
    500: "#2db4d9",
    600: "#28a2c3",
    700: "#2186a1",
    800: "#1b6e85",
    900: "#155567",
  },
  blue: {
    50: "#f0f7ff",
    100: "#c7e0fe",
    200: "#9ec9fd",
    300: "#6faffc",
    400: "#4094fb",
    500: "#2e7fe0",
    600: "#276abc",
    700: "#1e518f",
    800: "#184275",
    900: "#143660",
  },
  purple: {
    50: "#f9f5ff",
    100: "#e7d9fe",
    200: "#d5bcfe",
    300: "#ba91fd",
    400: "#a772fc",
    500: "#8b45fb",
    600: "#742fe4",
    700: "#5f27bb",
    800: "#4f209a",
    900: "#3a1873",
  },
  pink: {
    50: "#fff5f9",
    100: "#fed6e8",
    200: "#feb4d5",
    300: "#fd82b9",
    400: "#fc56a1",
    500: "#e12f7f",
    600: "#c1286d",
    700: "#9e2159",
    800: "#7c1a46",
    900: "#5b1334",
  },
  red: {
    50: "#fff5f5",
    100: "#fed7d5",
    200: "#fdb3af",
    300: "#fc817b",
    400: "#fc6058",
    500: "#e93930",
    600: "#c53129",
    700: "#9f2721",
    800: "#87211c",
    900: "#631814",
  },
  orange: {
    50: "#fffaf4",
    100: "#feead2",
    200: "#fdd19c",
    300: "#fbab49",
    400: "#e1902f",
    500: "#c17c28",
    600: "#a36922",
    700: "#82531b",
    800: "#664215",
    900: "#543611",
  },
  primary: {
    main: "#f3be32",
    50: "#fef7e3",
    100: "#fddd8b",
    200: "#f3be32",
    300: "#cc9f2a",
    400: "#b78f26",
    500: "#9a7820",
    600: "#82651b",
    700: "#685116",
    800: "#584512",
    900: "#3f320d",
  },
}

export default colors
