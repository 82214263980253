import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
      }
    }
  }
`

interface SeoProps {
  title?: string
  description?: string
}

const Seo: React.FC<SeoProps> = ({ title, description }) => {
  const { site } = useStaticQuery(getData)

  const { siteDesc, siteTitle, siteUrl, image } = site.siteMetadata
  return (
    <>
      <meta
        id="description"
        name="description"
        content={description || siteDesc}
      />
      <meta id="image" name="image" content={image} />

      {/* Facebook Card */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || siteTitle} />
      <meta property="og:description" content={description || siteDesc} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      <meta property="og:image" content="400" />
      <meta property="og:image:height" content="300" />
    </>
  )
}

export default Seo
