import { useStaticQuery, graphql } from 'gatsby'

const useStaticData = () =>
  useStaticQuery(graphql`
    query SiteQuery {
      contentfulHomePage {
        heroVideos {
          id
          url
        }

        projects {
          id
          image {
            url
          }
        }
      }

      allContentfulService(sort: { createdAt: ASC }) {
        nodes {
          id
          title
          description {
            description
          }
        }
      }

      allContentfulBlogPost {
        nodes {
          body {
            childMarkdownRemark {
              html
            }
          }
          title
        }
      }

      logo_brand: contentfulAsset(title: { eq: "Logo Brand" }) {
        id
        url
        title
      }

      logo_small: contentfulAsset(title: { eq: "Logo Small" }) {
        id
        url
        title
      }

      riverstreet: contentfulAsset(title: { eq: "Savannah Riverside" }) {
        id
        url
        title
      }
    }
  `)

export default useStaticData
