import React, { FC } from 'react'
import { motion } from 'framer-motion'
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import Footer from 'components/Footer'
import useSiteMetadata from 'hooks/useSiteMetadata'
import MobileNav from 'components/MobileNav'
import Seo from 'components/Seo'
import Header from 'components/Header'

interface DefaultTemplateProps {
  children: React.ReactNode
}

const DefaultTemplate: FC<DefaultTemplateProps> = ({ children }) => {
  const siteMeta = useSiteMetadata()
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <>
      <Seo title={siteMeta.title} />
      <Box
        as={motion.div}
        height="100%"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
      >
        <Box id="outer-container" height="100%" p={0}>
          {isMobile ? <MobileNav /> : <Header />}
          <Flex id="page-wrap" flexDirection="column" height="100%">
            <Box flexGrow={1} position="relative">
              {children}
            </Box>
            <Footer />
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default DefaultTemplate
