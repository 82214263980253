import React from "react"
import {
  Box,
  UnorderedList,
  ListItem,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import menuItems from "content/links.json"
import useStaticData from "hooks/useStaticData"

export default function Header() {
  const data = useStaticData()
  return (
    <Flex
      as="nav"
      position="fixed"
      zIndex="sticky"
      top={0}
      left={0}
      width="100%"
      maxWidth="100%"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="blackAlpha.800"
      p={4}
      pl={10}
    >
      
      <Image src={data.logo_small.url} alt={data.logo_small.title} height={50}></Image>
      <Flex
        as={UnorderedList}
        alignItems="center"
        justifyContent="right"
        pr={5}
      >
        {menuItems.map((item, index) => (
          <ListItem key={index} listStyleType="none">
            <Box
              as={Link}
              to={item.path}
              color="white"
              textTransform="uppercase"
              fontSize={18}
              fontFamily="'Barlow Condensed', sans-serif"
              mx={2}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  color: "primary.200",
                },
              }}
            >
              {item.text}
            </Box>
            <Box as="span" color="white">
              {index !== menuItems.length - 1 && "•"}
            </Box>
          </ListItem>
        ))}
        
      </Flex>
    </Flex>
  )
}

